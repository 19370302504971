<template>
  <v-list dense>
    <v-list-item>
      <span class="headline">Filtros</span>
      <v-icon class="ml-2" color="secondary">mdi-tune</v-icon>
    </v-list-item>
    <v-divider />
    <v-list-item-group>
      <v-list-item>
        <v-list-item-content class="pl-3">
          <v-switch
            v-model="showOnlyAvailable"
            flat
            color="secondary"
            label="Sólo disponibles"
          ></v-switch>
        </v-list-item-content>
      </v-list-item>
      <!--small>{{ priceRangeFilter }}localStorage.getItem("token")</small-->
      <!--v-list-item>
        <v-list-item-content class="px-3">
          <p>Rango de precios</p>
          <v-range-slider
            class="pt-5"
            v-model="priceRangeFilter"
            :max="highestPrice"
            :min="lowestPrice"
            thumb-label
          >
            <template v-slot:thumb-label="{ value }">
              {{ value | parseToK }}
            </template>
          </v-range-slider>
        </v-list-item-content>
      </v-list-item-->
    </v-list-item-group>
  </v-list>
</template>
<script>
import { mapState } from "vuex";

export default {
  filters: {
    parseToK(amount) {
      if (amount < 1000) return amount;
      return String(parseInt(amount / 1000)) + "k";
    }
  },
  computed: {
    ...mapState({
      lowestPrice: state => state.properties.itemsLowestPrice,
      highestPrice: state => state.properties.itemsHighestPrice
    }),
    showOnlyAvailable: {
      get() {
        return this.$store.state.properties.filterOnlyAvailable;
      },
      set(newValue) {
        this.$store.commit("properties/setFilterOnlyAvailable", newValue);
      }
    },
    priceRangeFilter: {
      get() {
        return this.$store.state.properties.filterPriceRange;
      },
      set(newValue) {
        this.$store.commit("properties/setFilterPriceRange", newValue);
      }
    }
  }
};
</script>
